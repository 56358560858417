@tailwind base;
@tailwind components;
@tailwind utilities;

.table-products {
  overflow-x: auto;
}

.layout-bg-color {
  background-color: white;
}
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  height: 150px;
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-pagination {
  position: relative !important;
}
.swiper-pagination-bullet-active {
  background-color: #41200c !important;
}

.overlay::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.196);
  z-index: 9;
}
